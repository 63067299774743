import { createApp } from 'vue'
import App from './App.vue'
import './base.css'
import router from '@/router'
import 'vant/lib/index.css';
import vant from 'vant'
import {createPinia} from 'pinia'
import wx from 'weixin-js-sdk'
import { createPersistedState } from 'pinia-plugin-persistedstate' // 这里


const pinia = createPinia()
pinia.use( createPersistedState({
    auto: true,
  }))


const app = createApp(App)
app.config.globalProperties.$wx = wx
app.use(router)
.use(vant)
.use(pinia)
.mount('#app')