import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path:'/',
        name:'cover',
        component:()=>import('@/pages/Cover'),
        meta:{
            keepAlive: true ,
          },
    },
    {
        path:'/list',
        name:'list',
        component:()=>import('@/pages/List'),
        meta:{
            keepAlive: true ,
          },
    },
    {
        path:'/design',
        name:'design',
        component:()=>import('@/pages/Design')
    },
    {
        path:'/cart',
        name:'cart',
        component:()=>import('@/pages/Cart'),
        meta:{
            keepAlive: true ,
          },
    },
    {
        path:'/cartDetail',
        name:'cartDetail',
        component:() => import('@/pages/Cart/CartDetail')
    },
    {
        path:'/order',
        name:'order',
        component:()=>import('@/pages/Order')
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})


export default router